.loadingClock {
    position: relative;
    margin: 24px auto;
    height: 200px;
    width: 200px;
    border-radius: 100%;
}

.loadingClock::after {
    content: ' ';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: #fff;
    transform: translate(-50%, -50%);
}

.lgHand {
    position: absolute;
    top: calc(50% - 2px);
    left: 50%;
    width: 40%;
    height: 8px;
    border-radius: 8px;
    background: #fff;
    transform-origin: center left;
    animation: spin 2s linear infinite;
}

.smHand {
    position: absolute;
    top: calc(50% - 2px);
    left: 50%;
    width: 30%;
    height: 8px;
    border-radius: 8px;
    background: #fff;
    transform-origin: center left;
    animation: spin 15s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}