@font-face {
    font-family: 'D-DIN';
    src: url('../fonts/D-DIN.woff2') format('woff2'),
        url('../fonts/D-DIN.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'D-DIN';
    src: url('../fonts/D-DIN-Italic.woff2') format('woff2'),
        url('../fonts/D-DIN-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'D-DIN';
    src: url('../fonts/D-DIN-Bold.woff2') format('woff2'),
        url('../fonts/D-DIN-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'D-DIN Condensed';
    src: url('../fonts/D-DINCondensed.woff2') format('woff2'),
        url('../fonts/D-DINCondensed.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'D-DIN Condensed';
    src: url('../fonts/D-DINCondensed-Bold.woff2') format('woff2'),
        url('../fonts/D-DINCondensed-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'D-DIN Exp';
    src: url('../fonts/D-DINExp.woff2') format('woff2'),
        url('../fonts/D-DINExp.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'D-DIN Exp';
    src: url('../fonts/D-DINExp-Bold.woff2') format('woff2'),
        url('../fonts/D-DINExp-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'D-DIN Exp';
    src: url('../fonts/D-DINExp-Italic.woff2') format('woff2'),
        url('../fonts/D-DINExp-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@import '~modern-css-reset/src/reset.css';