.entry-joke {
    display: grid;
    grid-template-columns: minmax(48px, 1fr) minmax(200px, 400px) minmax(48px, 1fr);
    column-gap: 16px;
}

.jokes .joke {
    margin-bottom: 32px;
}

.joke {
    display: flex;
    align-items: stretch;
}

.flip {
    position: relative;
    margin: auto;
    width: 100%;
    height: max-content;
    transition: height 0.5s;
}

.flip .card {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transition: transform 0.5s;
    transform-style: preserve-3d;
    backface-visibility: hidden;
}

.card {
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin: 0 auto;
    max-width: 400px;
    width: 100%;
    position: relative;
    min-height: max-content;
}

.open {
    transform: rotateY(180deg);
}

.flipped .open {
    transform: rotateY(0);
    transition: transform 0.5s;
}

.flipped .closed {
    transform: rotateY(180deg);
    transition: transform 0.5s;
}

.punchline,
.lead {
    padding: 32px;
    border-radius: 8px;
    text-align: center;
    height: 100%;
    min-height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.punchline {
    background-color: #fefefe;
}

.title {
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;
    margin-bottom: 0;
    text-align: center;
    color: #fff;
}

.title::before {
    content: "";
    display: block;
    width: 20px;
    height: 24px;
    float: right;
}

.punchlineText {
    font-size: 32px;
    line-height: 32px;
    text-align: center;
    margin: 0;
    padding: 0;
    flex-shrink: 0;
}

.punchlineText p {
    margin: 0;
    padding: 0;
}

.flipped .closed .punchline {
    display: none;
}

.flipped .open .lead {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.flipped .open .punchline {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.open .lead {
    padding: 16px;
}

.open .title {
    font-size: 24px;
    font-weight: normal;
}

.badge {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    height: 64px;
    width: 64px;
    transform: translate(25%, -25%) rotate(35deg);
}

.badge svg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}

.badgeText{
    display: block;
    position: relative;
    text-align: center;
    z-index: 1;
    font-size: 16px;
    line-height: 1;
    font-weight: 700;
    color: #fff;
    padding-top: 4px;
}

.badgeText span:first-child {
    display: block;
    font-size: 10px;
    line-height: 0.5;
    width: min-content;
}

.badgeText span:first-child::after {
    content: " ";
    display: inline-block;
    width: 100%;
    height: 2px;
    background-color: #fff;
}