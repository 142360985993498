.spinner {
    /* change color here */
    color: inherit;
}

.spinner,
.spinner div {
    box-sizing: border-box;
}

.spinner {
    display: block;
    position: relative;
    margin: 32px auto;
    width: 120px;
    height: 120px;
}

.spinner div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border: 12px solid currentColor;
    border-radius: 50%;
    animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: currentColor transparent transparent transparent;
}

.spinner div:nth-child(1) {
    animation-delay: -0.45s;
}

.spinner div:nth-child(2) {
    animation-delay: -0.3s;
}

.spinner div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}