.dots {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
}

.dot {
    border-radius: 100%;
    margin: 0 .3%;
    padding-bottom: 1.4%;
    position: relative;
    transition: all .3s ease;
    width: 1.4%;
    background-color: rgba(255, 255, 255, .3);
}

.dot:after {
    border-radius: 100%;
    content: " ";
    display: block;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    -webkit-transform: translateY(200%);
    -ms-transform: translateY(200%);
    transform: translateY(200%);
    transition: all .3s ease;
    width: 100%;
}

.active::after {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
}

.waiting {
    animation: bounce .2s infinite alternate;
    -webkit-animation: bounce .2s infinite alternate;
}

@keyframes bounce {
    from {
        transform: translateY(0px);
    }

    to {
        transform: translateY(-8px);
    }
}

@-webkit-keyframes bounce {
    from {
        transform: translateY(0px);
    }

    to {
        transform: translateY(-8px);
    }
}